export default {
  actions: {
    cancel: "cancel",
    close: "close",
    copy: "copy",
    edit: "edit",
    remove: "remove",
    retry: "retry",
    save: "save",
    show: "show",
    undo: "undo",
  },
  components: {
    downloadCard: {
      betaCheckboxLabel:
        "Note: When using the Audience Mic beta version, acoustic feedback may occur. Please level the Connect Station’s output volume properly or install additional safety measures to avoid any hearing damage.",
      button: "Download Manager %{version}",
      description:
        "After making sure you configured the Manager data above, you can download the installation files for your MobileConnect Manager.",
      licenseCheckboxLabel:
        'By downloading the MobileConnect Manager, you agree to use <a target="_blank" href="%{link}">3rd Party Docker Containers</a>.',
      postButton: "The Manager installation steps are described in detail in the MobileConnect Documentation.",
      privacyCheckboxLabel:
        'By downloading the MobileConnect Manager, you agree to our <a target="_blank" href="%{termsLink}">Terms of Use</a> and <a target="_blank" href="%{privacyLink}">Privacy policy</a>.',
      title: "2. Get your MobileConnect Manager",
      versionHint: "Latest Manager version: %{version}",
      versionHintShowFeatures: "Selected Manager version: %{version}",
      label: "Select Manager version:",
    },
    header: {
      documentation: "Documents",
      logOut: "Log Out",
    },
    footerLinks: {
      imprint: "Imprint",
      privacy: "Privacy & Cookies",
      terms: "Terms of Use",
    },
    invitationItem: {
      accept: "accept",
      as: "as",
      reject: "reject",
      to: "To",
    },
    lastUpdatedAt: {
      label: "Last Updated:",
    },
    orgaCard: {
      description:
        "When using the MobileConnect Station in Manager Mode, you can download and setup your MobileConnect Manager here. In order to configure and deploy the MobileConnect system in your network, you first need to enter your MobileConnect Manager data.",
      errors: {
        invalidChars: "The Manager name can not contain the following chars: %{forbiddenChars}",
        maxLength: "The Manager name can not contain more than %{count} characters",
        nameRequired: "Please enter a name for your Manager",
        redirectUriRequired: "Please enter a valid HTTPS URL of your MobileConnect Manager.",
        redirectUriHttps: "Please enter a valid HTTPS resource.",
      },
      orgaName: {
        info: "Enter a name for your Manager. You can use the name of your organization (e.g. Sennheiser Streaming Technologies)",
        label: "Manager Name",
        placeholder: "Enter the name for your Manager",
      },
      redirectUri: {
        info: "Add a redirect URL which should match the URL/domain name used to access your MobileConnect Manager in your network. For more information, refer to the MobileConnect Documentation.",
        label: "Redirect URL",
        placeholder:
          "Enter URL of your MobileConnect Manager, e.g. https://mc-manager.yourdomain.com, or https://mc-manager",
      },
      redirectUriChanged:
        "The redirect URL was changed. You need to download the MobileConnect Manager files again and reinstall the Manager, for the changes to be applicable. Check the MobileConnect Documentation for more information.",
      submitButton: "Save",
      title: "1. Set up your MobileConnect Manager",
      titleNew: "Register New Manager",
    },
    organizationSelector: {
      ariaLabel: "Your organization",
      create: "Register New Manager",
      noItems: "Manage Manager",
    },
    pageHeader: {
      ariaPersonalMenu: "Personal menu",
    },
    pluginsDownloadCard: {
      crestron: {
        button: "Download",
        licenceLabel:
          'By downloading the %{name}, you accept the <a href="%{licenseLink}" target="_blank" rel="noreferrer">End User License Agreement</a>.',
        title: "Crestron module",
        version: "Version: %{version}",
      },
      description: "Download plugins for using the MobileConnect system with your media control systems.",
      extron: {
        description:
          'You can <a href="%{link}" target="_blank" rel="noreferrer">download the driver</a> from the Extron driver store.',
        title: "Extron driver ",
      },
      other: {
        description:
          'Are you interested in building your own integration with the MobileConnect system? Have a look at what the MobileConnect REST API offers. Find out more at <a href="https://docs.sennheiser-connect.com/mobile-connect-api/index.html" target="_blank" rel="noreferrer">MobileConnect API documentation</a>.',
        title: "Other systems",
      },
      title: "Download 3rd Party Plugins",
    },
    standaloneIntroduction: {
      button: "Understood",
      line1:
        "You can download the latest version of <strong>MobileConnect Manager</strong>, edit your user profile and manage members of your organization here. When operating your system in <strong>Standalone mode</strong>, you can download the latest CS2 firmware here as well.",
      line2: "For further information, please check out the Documentation page.",
      title: "Welcome to MobileConnect Accounts",
    },
    standaloneDownloadCard: {
      betaCheckboxLabel:
        "Note: When using the Audience Mic beta version, acoustic feedback may occur. Please level the Connect Station’s output volume properly or install additional safety measures to avoid any hearing damage.",
      button: "Download Firmware %{version}",
      description:
        "When using the MobileConnect Station in Standalone Mode, you can find firmware updates for the MobileConnect Station v2 here. In case you are using MobileConnect in Manager Mode or when having a MobileConnect Station v1 you can install firmware updates over the MobileConnect Manager interface.",
      label: "Select Firmware version:",
      postButton: "",
      privacyCheckboxLabel:
        'By downloading the MobileConnect Firmware, you accept the <a target="_blank" href="%{agreementLink}">End User License Agreement</a>.',
      title: "Download MCS v2 Firmware",
      versionHint: "Latest Firmware version: %{version}",
    },
    userRole: {
      roles: {
        1: "Owner",
        2: "Admin",
        3: "Moderator",
      },
      roleInfos: {
        1: "Can view/edit Manager and its members. Has full access to the MobileConnect Manager.",
        2: "Has full access to the MobileConnect Manager.",
        3: "Has limited access to the MobileConnect Manager, i.e. can access Mobile App Configuration.",
      },
    },
    whatsNewLink: {
      text: "(What’s new?)",
    },
  },
  errors: {
    "403": {
      action: "Go to your profile instead",
      message: "",
      subtitle: "You are not allowed to see this page",
      title: "Forbidden",
    },
    "404": {
      action: "Go to Overview",
      message: "",
      subtitle: "Page not found",
      title: "Oops",
    },
    500: {
      action: "Reload Page",
      message: "If reloading doesn’t help, please contact us.",
      subtitle: "Unexpected Error",
      title: "Sorry. Something unexpected happened. Our team is on it.",
    },
    authCallbackError: "Sorry, we could not log you in. But we will try again.",
    noConnection: {
      message:
        "We could not connect to the login server. Please check your Internet and firewall settings and try again.",
      subtitle: "No Internet connection",
      title: "Oh-Oh",
    },
    requestFailed: "Sorry. The request could not be processed.",
    websocketDisconnected:
      "Live updates are temporarily interrupted. To stay up to date with changes, please reload your browser.",
  },
  navigation: {
    feedback: "Contact Us",
    items: {
      betaAccess: "Beta Access",
      documentation: "Documentation",
      downloads: "Software Downloads",
      overview: "Overview",
      profile: "Profile",
      members: "Members",
    },
    title: "MobileConnect Accounts",
  },
  searchPlaceholder: "Search …",
  views: {
    betaAccess: {
      access: {
        disabled: "Disabled",
        enabled: "Enabled",
      },
      breadcrumb: "Beta Access",
      changeDialog: {
        description:
          "Please make sure you have aligned with all required stakeholders (e.g. sales, customer, product team) before granting or revoking access to beta releases.",
        titleGrant: "Are you sure you want to grant Beta Access?",
        titleRevoke: "Are you sure you want to revoke Beta Access?",
      },
      headers: {
        hasBetaAccess: "Beta Access",
        name: "Manager",
      },
      pageTitle: "Beta Access",
      serverError: "Something went wrong updating the organization. Please try again.",
      title: "Manage Beta Access",
      titleInfo:
        "Invite customers and sales colleagues to use the latest beta versions of MobileConnect Manager and Firmware.",
    },
    downloadsPage: {
      breadcrumb: "Software Downloads",
      navi: {
        manager: "Manager Mode",
        plugins: "3rd Party Plugins",
        standalone: "Standalone Mode",
      },
    },
    imprint: {
      breadcrumb: "Imprint",
      pageTitle: "MobileConnect Imprint",
      title: "Imprint",
    },
    manager: {
      breadcrumb: "Manager Setup",
      pageTitle: "MobileConnect Software Downloads",
    },
    members: {
      breadcrumb: "Members",
      actions: {
        addUser: "Add Member",
      },
      addUserDialog: {
        confirmation: {
          description: "The following message was sent to <strong>%{email}</strong>",
          emailText: `<p>Hi %{name},</p>
            <p>You are invited to join the assistive listening system Sennheiser MobileConnect.
            Please follow the steps below to accept this invitation:</p>
            <ul>
              <li>Create a new account or log in to your existing one <a href="%{accountsLink}" target="_blank">here</a> using %{email}</li>
              <li>Navigate to your <a href="%{profileLink}" target="_blank">profile page</a> and accept the invitation</li>
              <li>Enjoy using the <a href="%{managerLink}" target="_blank">MobileConnect Manager</a></li>
            </ul>`,
          label: "Email Text:",
          title: "Invitation Sent",
        },
        errors: {
          emailInvalid: "Please enter a valid email address of the person you want to invite",
          emailRequired: "Please enter the email address of the person you want to invite",
          nameRequired: "Please enter the name of the person you want to invite",
        },
        labels: {
          accountType: "Choose what role the member will have in your Manager:",
          email: "Email:",
          name: "Name:",
        },
        placeholders: {
          email: "",
          name: "",
        },
        title: "Add Member",
      },
      editUserDialog: {
        errors: {
          emailInvalid: "Please enter a valid email address of the person you want to invite",
          emailRequired: "Please enter the email address of the person you want to invite",
          nameRequired: "Please enter the name of the person",
        },
        labels: {
          accountType: "Account Type:",
          email: "Email:",
          name: "Name:",
        },
        placeholders: {
          email: "",
          name: "",
        },
        removeUserButton: "Remove member",
        removeUserDialog: {
          description: "Do you really want to remove member “%{name}” with email “%{email}” from your Manager?",
          error: "Something went wrong removing the member. Please try again.",
          submitButton: "Remove",
          title: "Remove Member",
        },
        title: "Edit %{name}’s Membership",
      },
      footer: {
        info: "Adding members is supported ONLY with Manager version 1.2.0 and higher.",
      },
      headers: {
        accountType: "Account type",
        edit: "Edit",
        email: "Email",
        name: "Name",
      },
      pageTitle: "MobileConnect Members",
      removeInvitationDialog: {
        title: "Are you sure you want to remove this invitation?",
      },
      status: {
        1: "Pending",
        2: "Rejected",
      },
      title: "Manage Members",
      titleInfo: "Invite new members to your Manager and manage their permissions.",
    },
    notFound: {
      breadcrumb: "MobileConnect Accounts",
      title: "Sorry, but the page you are looking for does not exist.",
    },
    overview: {
      breadcrumb: "Overview",
      cards: {
        thirdparty: {
          actionTitle: "Download 3rd party plugins",
          text: "Find 3rd party plugins for integrating MobileConnect in your media control system.",
          title: "3rd party plugin Downloads",
        },
        firmware: {
          actionTitle: "Download MCS v2 Firmware",
          text: "When using the MobileConnect Station in Standalone Mode, you can find firmware updates for the MobileConnect Station v2 here.",
          title: "Firmware Downloads",
        },
        manager: {
          actionTitle: "Download Manager software",
          text: "When using the MobileConnect Station in Manager Mode, you can download and setup your MobileConnect Manager here.",
          title: "Manager Downloads",
        },
        members: {
          actionTitle: "View MobileConnect members",
          text: "View and edit your MobileConnect Manager’s members here.",
          title: "Members",
        },
        profile: {
          actionTitle: "View your profile",
          text: "Edit your MobileConnect profile and view all MobileConnect Managers you are a member of.",
          title: "Profile",
        },
      },
      description:
        "Here you can download the latest MobileConnect Manager and Standalone Mode software, view your profile or manage your MobileConnect Manager’s members.",
      pageTitle: "MobileConnect Accounts",
      title: "Overview",
      welcome: "Welcome, %{name}",
    },
    privacy: {
      breadcrumb: "Privacy Policy",
      pageTitle: "MobileConnect Privacy Policy",
      title: "Privacy Policy for MobileConnect Manager",
    },
    profile: {
      breadcrumb: "Profile",
      errors: {
        unexpectedErrorOnSave: "Profile could not be saved. Please try again later.",
        required: {
          name: "Please enter your name.",
        },
      },
      invitations: {
        headers: {
          actions: "Actions",
          name: "Manager Name",
        },
        headline: "You have open invitations to these Managers:",
      },
      labels: {
        email: "Email",
        name: "Name",
      },
      organizations: {
        headers: {
          accountType: "Account Type",
          redirectUri: "Manager URL",
          name: "Manager Name",
        },
        headline: "You are member of these Managers:",
        joinDescription1:
          "Join a MobileConnect Manager by contacting your Organization Owner and asking them to add you.",
        joinDescription2:
          "If you are the owner/responsible for the MobileConnect system you can also download and create a MobileConnect Manager within the Software Downloads section.",
        joinDescription3:
          "Joining or creating a MobileConnect Manager is not needed when using MobileConnect in Standalone Mode.",
        joinTitle: "Join or create a Manager",
      },
      pageTitle: "MobileConnect Profile",
      title: "Profile",
      titleInfo: "Edit your MobileConnect profile and view the Managers you are a member of.",
    },
    standaloneDownload: {
      pageTitle: "MobileConnect Standalone Software Download",
    },
    terms: {
      breadcrumb: "Terms of Use",
      docker: {
        consul:
          "<strong>Consul</strong> is a datacenter runtime for service discovery, configuration, and orchestration. For more information visit:",
        intro:
          "By downloading and using the MobileConnect Manager, you agree to use the following 3rd Party Docker Containers:",
        mssql:
          "<strong>Microsoft SQL Server</strong> is a relational database management system. For more information visit:",
        vernemq: "<strong>VerneMQ</strong> is an open source messaging broker. For more information visit:",
        title: "3rd Party Docker Containers",
        traefik: "<strong>traefik</strong> is an open source Edge Router. For more information visit:",
      },
      intro: "By downloading and using our products, you agree to the Terms of Use and License Agreements below:",
      pageTitle: "MobileConnect Terms of Use",
      title: "Terms of Use and License Agreement",
    },
  },
};
